import {createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "rgb(21, 24, 26)"
      }
    }
  },
  palette: {
    type: "dark",
    primary: {
      main: "#1d0f59"
    },
    secondary: {
      main: "#f44336"
    }
  },
  typography: {
    fontSize: 16
  }
});

export const marketingTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "rgb(21, 24, 26)"
      }
    },
    MuiTableHead:{
      root:{
        display:"none"
      }
    }
  },
  palette: {
    type: "dark",
    primary: {
      main: "#590f0f"
    },
    secondary: {
      main: "#f44336"
    }
  },
  typography: {
    fontSize: 16
  }
});

export const salesTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "rgb(21, 24, 26)"
      }
    },
    MuiTableHead:{
      root:{
        display:"none"
      }
    }
  },
  palette: {
    type: "dark",
    primary: {
      main: "#0f5920"
    },
    secondary: {
      main: "#f44336"
    }
  },
  typography: {
    fontSize: 16
  }
});

export const accountTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "rgb(21, 24, 26)"
      }
    },
    MuiTableHead:{
      root:{
        display:"none"
      }
    }
  },
  palette: {
    type: "dark",
    primary: {
      main: "#590f31"
    },
    secondary: {
      main: "#f44336"
    }
  },
  typography: {
    fontSize: 16
  }
});
export const financeTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "rgb(21, 24, 26)"
      }
    },
    MuiTableHead:{
      root:{
        display:"none"
      }
    }
  },
  palette: {
    type: "dark",
    primary: {
      main: "#0f593f"
    },
    secondary: {
      main: "#f44336"
    }
  },
  typography: {
    fontSize: 16
  }
});
export const itTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "rgb(21, 24, 26)"
      }
    },
    MuiTableHead:{
      root:{
        display:"none"
      }
    }
  },
  palette: {
    type: "dark",
    primary: {
      main: "#0f3a59"
    },
    secondary: {
      main: "#f44336"
    }
  },
  typography: {
    fontSize: 16
  }
});

export const pTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "rgb(21, 24, 26)"
      }
    },
    MuiTableHead:{
      root:{
        display:"none"
      }
    }
  },
  palette: {
    type: "dark",
    primary: {
      main: "#ff8700"
    },
    secondary: {
      main: "#f44336"
    }
  },
  typography: {
    fontSize: 16
  }
});
export const fmTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "rgb(21, 24, 26)"
      }
    },
    MuiTableHead:{
      root:{
        display:"none"
      }
    }
  },
  palette: {
    type: "dark",
    primary: {
      main: "#8d00ff"
    },
    secondary: {
      main: "#f44336"
    }
  },
  typography: {
    fontSize: 16
  }
});

export const beTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "rgb(21, 24, 26)"
      }
    },
    MuiTableHead:{
      root:{
        display:"none"
      }
    }
  },
  palette: {
    type: "dark",
    primary: {
      main: "#0f004a"
    },
    secondary: {
      main: "#f44336"
    }
  },
  typography: {
    fontSize: 16
  }
});
export const stTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "rgb(21, 24, 26)"
      }
    },
    MuiTableHead:{
      root:{
        display:"none"
      }
    }
  },
  palette: {
    type: "dark",
    primary: {
      main: "#229000"
    },
    secondary: {
      main: "#f44336"
    }
  },
  typography: {
    fontSize: 16
  }
});
export const getDepartmentTheme = (department)=>{
    var currentTheme;
    switch(department){
      case "PE": currentTheme = theme
      break;
      case "MK": currentTheme = marketingTheme
      break;
      case "S": currentTheme = salesTheme
      break;
      case "AM": currentTheme = accountTheme
      break;
      case "F": currentTheme = financeTheme
      break;
      case "IT": currentTheme = itTheme
      break;
      case "P": currentTheme = pTheme
      break;
      case "FM": currentTheme = fmTheme
      break;
      case "BE": currentTheme = beTheme
      break;
      case "ST": currentTheme = stTheme
      break;
      default: currentTheme = theme;
    } 
    return currentTheme
  }