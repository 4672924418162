import React from "react";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableBody } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import Dialog from '@material-ui/core/Dialog';
import {SmallSlider, LevelTableCell,ShowMoreButton,sliceText} from "./Helpers"
import Paper from "@material-ui/core/Paper";
const styles = {
  skillTitle: {
    color: "#dede8f",
    fontWeight: "bold"
  }
};

class SimpleDialog extends React.Component {
  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  render() {
    const { classes, onClose, selectedValue, ...other } = this.props;

    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
        <Paper style={{color:"rgb(212, 212, 212)", padding:20}}>
          <ReactMarkdown source={this.props.text} />
        </Paper>
      </Dialog>
    );
  }
}

class ShowMoreDialogButton extends React.Component {

  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = value => {
    this.setState({ open: false });
  };
  render(){
    return(
      <div>
        <ShowMoreButton onClick={this.handleClickOpen} text={this.props.text}/>
        <SimpleDialog open={this.state.open} onClose={this.handleClose} text={this.props.text}
        />
      </div>
    )
  }
}

export const TableRowItem = ({ skill, position, props, classes, getStyle, levels }) => {
  return (
  <TableRow key={skill.RowKey}>
    <TableCell scope="row" style={styles.skillTitle}>
    <div style={{display:"block", width:"150px"}}>
      {skill.RowKey}
      <div>
      <SmallSlider
        value={skill.level}
        classes={{
          // container: classes.slider,
          track: classes.track,
          thumb: classes.thumb
        }}
        min={-1}
        max={4}
        step={1}
        onChange={(event, value) => {
          props.updateSkillValue({ ...skill, level: value });
        }}
        onChangeCommitted={event => {
          props.saveSkill(props.department, { ...skill });
        }}
      /></div></div>
    </TableCell>
    {levels.map((level, index) => (
      <LevelTableCell key={index} scope="row" style={getStyle(skill.level, level, position)}>
        {position === level && <div className="ribbon"><span>*</span></div> }
        <div style={{paddingRight:20}} >        
          <ReactMarkdown source={sliceText(skill[`Level${level}`])} />
        </div>
        <ShowMoreDialogButton text={skill[`Level${level}`]}/>
      </LevelTableCell>
    ))}
  </TableRow>
)};
export const HeadDiv = ({ title }) => (
  <div >{title}</div>
);

export const MKSkillSet = ({ skills, props, classes, getStyle, theme, levels }) => (
  <Table className="table table-striped flex-table">
    <TableHead style={{backgroundColor:props.theme.palette.primary.main, position:"sticky", top:120, zIndex:1050}} >
      <TableRow style={{height:"100px"}} >
        <TableCell align="left">
          <HeadDiv title="Skill" />
        </TableCell>
        {
          levels.map((level, index) =>(
              <TableCell key={index} align="left">
                <HeadDiv title={`level ${level + 1}`} />
              </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
    <TableBody>
      {skills.map(skill => (
         skill[props.positionCode] >0 && <TableRowItem
          key={skill.RowKey}
          levels={levels}
          skill={skill}
          position={skill[props.positionCode] - 1}
          props={props}
          classes={classes}
          getStyle={getStyle}
        />
      ))}
    </TableBody>
  </Table>
);
