export var mapping = {
  "PE": {
      "name": "Product Engineering",
      "sub": {
          "IC": {
              "name": "Interactive Content",
              "positions": {}
          },
          "BO": {
              "name": "Business Objects",
              "positions": {}
          },
          "WP": {
              "name": "Web portals",
              "positions": {}
          },
          "MC": {
              "name": "Multichannel",
              "positions": {}
          },
          "A": {
              "name": "Advertisement",
              "positions": {}
          }
      },
      "positions": {
          "JSE": "Junior Software Engineer",
          "SE": "Software Engineer",
          "SSE": "Senior Software Engineer",
          "PSE": "Principal Software Engineer",
          "EM": "Engineering Manager",
          "SPSE": "Senior Principal Software Engineer",
          "SEM": "Senior Engineering Manager",
          "DE": "Director of Engineering",
          "DSE": "Distinguished Software Engineer",
          "SDE": "Senior Director of Engineering",
          "VE": "VP of Engineering",
          "TE": "Technology Evangelist",
          "SVE": "SVP of Engineering",
          "CTO": "CTO"
      }
  },
  "BE": {
      "name": "Base engineering",
      "sub": {
          "T": {
              "name": "QA",
              "positions": {
                  "JE": "Junior QA Engineer",
                  "E": "QA Engineer",
                  "AE": "Automation Engineer",
                  "SE": "Senior QA Engineer",
                  "SAE": "Senior Automation Engineer",
                  "M": "QA Manager",
                  "SM": "Senior QA Manager"
              }
          },
          "DA": {
              "name": "Analytics",
              "positions": {
                  "J": "Junior Data Analyst",
                  "DA": "Data Analyst",
                  "SDA": "Senior Data Analyst",
                  "MA": "Manager Analytics",
                  "VP": "VP of Analytics",
                  "SVP": "Senior VP of Analytics"
              }
          },
          "DO": {
              "name": "DevOps",
              "positions": {
                  "S": "Senior DevOps Engineer",
                  "M": "DevOps manager",
                  "P": "Principal DevOps Engineer"
              }
          },
          "D": {
              "name": "Data",
              "positions": {
                  "E": "Data Engineer",
                  "DBE": "Database Engineer",
                  "SDE": "Senior Data Engineer",
                  "SDBE": "Senior Database Engineer",
                  "PDE": "Principal Data Engineer",
                  "PDBE": "Principal Database Engineer",
                  "EM": "Data Engineering Manager",
                  "SDEM": "Senior Data Engineering Manager"
              }
          },
          "AIML": {
              "name": "AI/ML",
              "positions": {
                  "S": "Senior Principal AI/ML Engineer",
                  "D": "Director of AI/ML"
              }
          },
          "ICLT": {
              "name": "Interactive Content Languages and Tools",
              "positions": {
                  "S": "Senior ICLT Engineer",
                  "P": "Principal ICLT Engineer",
                  "M": "ICLT Engineering Manager"
              }
          }
      },
      "positions": {}
  },
  "MK": {
      "name": "Marketing",
      "sub": {
          "MK": {
              "name": "Marketing",
              "positions": {}
          }
      },
      "positions": {
          "MA": "Marketing Associate",
          "AMM": "Associate Marketing Manager",
          "MM": "Marketing Manager",
          "DM": "Director of Marketing",
          "SDM": "Senior Director of Marketing",
          "VP": "VP of Marketing"
      }
  },
  "S": {
      "name": "Sales",
      "sub": {
          "AS": {
              "name": "Account Sales",
              "positions": {
                  "SC": "Sales Coordinator",
                  "AE": "Account Executive",
                  "SAE": "Senior Account Executive",
                  "SD": "Sales Director",
                  "VP": "VP of Sales"
              }
          },
          "AM": {
              "name": "Account Management",
              "positions": {
                  "AAM": "Associate Account Manager",
                  "AM": "Account Manager",
                  "SAM": "Senior Account Manager",
                  "LAM": "Lead Account Manager",
                  "DAM": "Director of Account Management",
                  "VP": "VP of Account Management"
              }
          }
      },
      "positions": {}
  },
  "BO": {
      "name": "Business Operations",
      "sub": {
          "F": {
              "name": "Finance",
              "positions": {
                  "JAKR": "Junior Accountant",
                  "AKR": "Accountant",
                  "CA": "Chief Accountant",
                  "C": "Controller",
                  "SMBO": "Senior Manager of Business Operations"
              }
          },
          "OF": {
              "name": "Office Facilities",
              "positions": {
                  "OA": "Office Administrator",
                  "OM": "Office Operations Manager",
                  "SOM": "Senior Office Operations Manager"
              }
          },
          "O": {
              "name": "Operations",
              "positions": {
                  "EA": "Executive Assistant",
                  "ASPC":"Administrative and Special Projects Coordinator"
              }
          }
      },
      "positions": {}
  },
  "IT": {
      "name": "I&T",
      "sub": {
          "IT": {
              "name": "I&T",
              "positions": {}
          }
      },
      "positions": {
          "SA": "Systems Administrator",
          "SSA": "Senior Systems Administrator",
          "SE": "Systems Engineer",
          "SSE": "Senior Systems Engineer",
          "PSE": "Principal Systems Engineer",
          "DIT": "Director of IT"
      }
  },
  "ST": {
      "name": "Support",
      "sub": {
          "HD": {
              "name": "Help Desk",
              "positions": {
                  "JS": "Junior Help Desk Specialist",
                  "S": "Help Desk Specialist",
                  "SS": "Senior Help Desk Specialist"
              }
          },
          "CT": {
              "name": "Content Management",
              "positions": {
                  "CM": "Content Manager",
                  "SCM": "Senior Content Manager"
              }
          },
          "PM": {
              "name": "Project Management",
              "positions": {
                  "PM": "Project Manager",
                  "SPM": "Senior Project Manager",
                  "SDR": "Senior Director of Support Team"
              }
          }
      },
      "positions": {}
  },
  "P": {
      "name": "Product",
      "sub": {
          "AO": {
              "name": "Ad Ops",
              "positions": {
                  "DRAO": "Director of Ad Ops",
                  "VPAO": "VP of Yield and Ad Ops"
              }
          },
          "DP": {
              "name": "Digital Properties",
              "positions": {
                  "PODP":"Product Owner, Digital Properties",
                  "PLDP": "Product Lead, Digital Properties",
                  "SPL": "Senior Product Lead",
                  "VPDP": "VP of Product, Digital Properties",
                  "VPAP": "VP of Product, All Products"
              }
          },
          "IC": {
              "name": "Interactive Content",
              "positions": {
                  "PLIC": "Product Lead, Interactive Content",
                  "SPL": "Senior Product Lead",
                  "VPIC": "VP of Product, Interactive Content",
                  "VPAP": "VP of Product, All Products"
              }
          },
          "NES": {
              "name": "Native Engagement Solutions",
              "positions": {
                  "VP": "VP of Product, Native Engagement Solutions",
                  "VPAP": "VP of Product, All Products"
              }
          }
      },
      "positions": {}
  },
  "PI": {
      "name": "People Innovation",
      "sub": {
          "PI": {
              "name": "People Innovation",
              "positions": {}
          }
      },
      "positions": {
          "JBP":"Junior People Inn Business Partner",
          "AD": "Administrator",
          "SRAD": "Senior Administrator",
          "BP": "Business Partner",
          "SRBP": "Senior Business Partner",
          "DR": "Director",
          "VP": "VP"
      }
  },
  "CI": {
      "name": "Creative Innovation",
      "sub": {
          "CS": {
              "name": "Content Strategy",
              "positions": {
                  "CS": "Content Strategist",
                  "SCS": "Senior Content Strategist"
              }
          },
          "VD": {
              "name": "Visual Design",
              "positions": {
                  "DVD": "Data Visualization Designer",
                  "SDVD": "Senior Data Visualization Designer",
                  "VD": "Visual Designer",
                  "SVD": "Senior Visual Designer",
                  "DrVD": "Director of Visual Design",
                  "SDrVD": "Senior Director of Visual Design",
                  "VP": "VP of Creative Innovation"
              }
          },
          "ID": {
              "name": "Interactive Design",
              "positions": {
                  "UXD": "UX Designer",
                  "SUXD": "Senior UX Designer",
                  "D": "Director of Interactive Design",
                  "SD": "Senior Director of Interactive Design"
              }
          }
      },
      "positions": {}
  },
  "MG": {
      "name": "Management",
      "sub": {
          "MG": {
              "name": "Management",
              "positions": {}
          }
      },
      "positions": {
          "CEO": "CEO",
          "PRES": "President",
          "TM": "Managment Team Member"
      }
  }
}