import {filter, find} from "rambda";
export const getStructure = (name) => {
    //return fetch(`/data/structure.json`).then(async res => { 
    return fetch(`https://arkadiuminternal.blob.core.windows.net/skills/structure.json`).then(async res => {
    if (res.ok) {
      let structure = await res.json();

      let structureForUser = structure.data.profiles.edges.filter(a=>a.node.activationState !== "2").map(r=> {return {username:r.node.fullName, email:r.node.user.email, reportsTo:(r.node.reportsTo?r.node.reportsTo.id:null), 
        id:r.node.id, activationState:(r.node.activationState?r.node.activationState:null)}})

      let manager = find(f=>{return f.email.toLowerCase() === name.toLowerCase()},structureForUser)
      
      if(manager.activationState === "0"){
        let manadgerRealEmail = manager.email;
        manager = find(f=>{return manager.username.toLowerCase() === f.username.toLowerCase() && f.activationState === "1"},structureForUser)
        manager.email = manadgerRealEmail;
      }
      
      let filtered = filter(f=>{return f.reportsTo === manager.id },structureForUser)
      filtered.unshift(manager)
      return filtered 
    } else {
      return {}
    }
  });
}