import React from "react";
import Table from "@material-ui/core/Table";
import GradeIcon from "@material-ui/icons/Place";
import {
  withStyles
} from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/MoreHoriz';
import TableCell from "@material-ui/core/TableCell";
import Slider from "@material-ui/core/Slider";

const textMax = 150
export const StarIcon = withStyles({
  root: {
    color: "#806a21",
    position: "absolute",
    top: "25%",
    right: "25%",
    fontSize:80 
  }
})(GradeIcon);

export const SmallSlider = withStyles({
  root: {
    
  },
  thumb: {

  },
  track:{
    
  }
})(Slider);

export const CellButton = withStyles({
  root: {
    position: "absolute",
    bottom: 0,
    right: 0
  }
})(IconButton);

export const LevelTableCell = withStyles({
  root: {
    position: "relative"
  }
})(TableCell);
export const sliceText = text => {
  if (text) {
    if (text.length > textMax) {
      return text.slice(0, textMax) + "..."
    } else return text
  }
}

export const FixedHeaderTable = withStyles({
  root:{

  }
})(Table)
export const ShowMoreButton = ({
    onClick,
    text
  }) => ( <div> {
      text && text.length > textMax && <CellButton onClick={onClick}> <PhotoCamera/> </CellButton>}</div > );