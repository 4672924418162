import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { runWithAdal } from "react-adal";
import { authContext } from "./adalConfig";
import { Provider } from "react-redux";
import store from './store';

runWithAdal(
  authContext,
  () => {
    ReactDOM.render(
      <Provider store={store}>
        <App />{" "}
      </Provider>,
      document.getElementById("root")
    );
  },
  false
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
