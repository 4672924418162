import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {mapping}from "../../departmentMapping";
export const LevelSelector = ({
  department,
  subDepartment,
  level,
  selectLevelValue,
  classes
}) => (
  <FormControl className={classes.formControl}>
    <InputLabel htmlFor="position-input">Level</InputLabel>
    <Select
      value={level}
      inputProps={{
        name: "position",
        id: "position-input"
      }}
      onChange={event => {
        selectLevelValue(event.target.value);
      }}
    >
      {
        Object.keys(Object.assign(mapping[department].sub[subDepartment].positions, mapping[department].positions))
        .map((key)=><MenuItem key={key} value={key}>{mapping[department].sub[subDepartment].positions[key]}</MenuItem>)
      }
    </Select>
  </FormControl>
);
