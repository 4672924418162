import {
  getSkills,
  getSkillsByUser,
  setSkillForUser,
  setOutcomeForUser,
  getOutcomesByUser
} from './../services/skillAppApiService';
import {
  createActions
} from 'redux-actions';
import {
  authContext
} from "../adalConfig";
import { getOutcomeTemplate } from '../services/outcomesService';
import { getStructure } from '../services/structureService';
import {mapping}from "../departmentMapping";
export const SHOW_LOADER = 'SHOW_LOADER';

export const HIDE_LOADER = 'HIDE_LOADER';

export const LOAD_SKILLS = "LOAD_SKILLS";
export const UPDATE_SKILL = "UPDATE_SKILL";

export const SELECT_DEPARTMENT = "SELECT_DEPARTMENT";

export const SELECT_SUB_DEPARTMENT = "SELECT_SUB_DEPARTMENT";

export const SELECT_LEVEL = "SELECT_LEVEL";

export const LOAD_OUTCOME_TEMPLATE = "LOAD_OUTCOME_TEMPLATE";

export const UPDATE_OUTCOME = "UPDATE_OUTCOME";

export const LOAD_OUTCOME = "LOAD_OUTCOME";

export const LOAD_STRUCTURE = "LOAD_STRUCTURE";

export const SELECT_CURRENT_USER = "SELECT_CURRENT_USER";

export const CAN_EDIT_SKILLS = "CAN_EDIT_SKILLS";
/*
{
  type
  payload
  error
  meta
}
*/

export const {

  showLoader,
  hideLoader,
  loadSkills,
  updateSkill,
  selectDepartment,
  selectSubDepartment,
  selectLevel,
  loadOutcomeTemplate,
  updateOutcome,
  loadOutcome,
  loadStructure,
  selectCurrentUser,
  canEditSkills
} = createActions(
  {
    SHOW_LOADER: () => true,
    HIDE_LOADER: () => false
  },
  LOAD_SKILLS,
  UPDATE_SKILL,
  SELECT_DEPARTMENT,
  SELECT_SUB_DEPARTMENT,
  SELECT_LEVEL,
  LOAD_OUTCOME_TEMPLATE,
  UPDATE_OUTCOME,
  LOAD_OUTCOME,
  LOAD_STRUCTURE,
  SELECT_CURRENT_USER,
  CAN_EDIT_SKILLS
);
//export const loadSkills = createAction(LOAD_SKILLS)
export const saveSkill = (department,skill) => {
  return dispatch => {
    setSkillForUser(department, skill).then(res => {
      dispatch(updateSkill(res.result));
    });
  }
}
export const updateSkillValue = (skill) => {
  return dispatch => {
    dispatch(updateSkill(skill));
  }
}
export const selectDepartmentValue = department => {
  return dispatch => {
    window.location.hash = `#${department}`
    var sub = Object.keys(mapping[department].sub)[0];
    var level = Object.keys(Object.assign(mapping[department].sub[sub].positions, mapping[department].positions))[0];
    dispatch(selectDepartment({department, sub, level}));
  }
}

export const selectSubDepartmentValue = (subDepartmentCode, level) => {
  return dispatch => {dispatch(selectSubDepartment(subDepartmentCode));  dispatch(selectLevel(level));}
}

export const selectLevelValue = levelCode => {
  return dispatch => {
    dispatch(selectLevel(levelCode))
  }
}
export const fetchSkills = (department, userName) => {
  return async dispatch => {
    dispatch(showLoader())
    let skills = await getSkills(department);
    let currentUser = userName?userName:authContext.getCachedUser().userName.toLowerCase()
    dispatch(selectCurrentUser(currentUser))
    dispatch(canEditSkills(currentUser.toLowerCase() === authContext.getCachedUser().userName.toLowerCase()))
    let userSkills = await getSkillsByUser(department,currentUser);
    let result = skills.map(s => {
      let userSkill = userSkills.find(e => e.RowKey === s.RowKey);
      return {
        ...s,
        level: userSkill ? userSkill.level : -1
      }
    })

    dispatch(loadSkills(result));

    dispatch(hideLoader())
  }
}


export const saveOutcome = (department,outcome) => {
  return dispatch => {
    setOutcomeForUser(department, outcome).then(res => {
      dispatch(updateOutcome(outcome));
    });
  }
}

export const fetchOutcomes = (department,position, userName) => {
  return async dispatch => {
    dispatch(showLoader())
    let currentUser = userName?userName:authContext.getCachedUser().userName.toLowerCase()
    dispatch(selectCurrentUser(currentUser))
    dispatch(canEditSkills(currentUser.toLowerCase() === authContext.getCachedUser().userName.toLowerCase()))
    let outcomeTemplate = await getOutcomeTemplate(department,position);
    let outcomeByUser = await getOutcomesByUser(department,currentUser)
    outcomeByUser = outcomeByUser.map(s=> {return { ...s , outcome:JSON.parse(s.outcome)}}) 

    dispatch(loadOutcome(outcomeByUser))
    dispatch(loadOutcomeTemplate(outcomeTemplate||""));

    dispatch(hideLoader())
  }
}

export const fetchStructure = () => {
  return async dispatch => {
    dispatch(showLoader())
    let structure = await getStructure(authContext.getCachedUser().userName);
    dispatch(loadStructure(structure))
    dispatch(hideLoader())
  }
}