import React, { Component } from "react";
import { connect } from "react-redux";
import {getDepartmentTheme} from './themes'
import "./App.css";
import {
  selectDepartmentValue,
  selectSubDepartmentValue,
  selectLevelValue,
  fetchOutcomes,
  fetchStructure,
  fetchSkills
} from "./actions";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Main from "./components/Pages/Main";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Outcomes from "./components/Pages/Outcomes";
import { DepartmentSelector } from "./components/MainMenuDropdowns/DepartmentSelector";
import { SubDepartmentSelector } from "./components/MainMenuDropdowns/SubDepartmentSelector";
import { LevelSelector } from "./components/MainMenuDropdowns/LevelSelector";
import { ReportsSelector } from "./components/MainMenuDropdowns/ReportsSelector";

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth:1400,
    margin:"auto"
  },
  header:{
    position:"sticky",
    top:-1,
    margin: 0,
    zIndex:1300
  },
  name: {
    position: "absolute",
    right: "15px"
  },
  logo: {
    margin: "10px 43px 10px 0px"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  progress: {
    minWidth: 60,
    minHeight: 60,
    margin: "10px 43px 10px 0px"
  }
});
class App extends Component {
  state = {
    value: 0
  };

  componentWillMount(){
      this.props.fetchStructure();
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <MuiThemeProvider theme={getDepartmentTheme(this.props.department)}>
        <div >
          <div className={classes.root}>
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <div className={classes.header}>
                    <AppBar position="static">
                      <Toolbar>
                        {this.props.isLoading ? (
                          <CircularProgress
                            className={classes.progress}
                            color="secondary"
                            value={100}
                            variant="indeterminate"
                          />
                        ) : (
                          <img
                            alt=""
                            src="/img/logo.png"
                            width="60px"
                            height="60px"
                            className={classes.logo}
                          />
                        )}
                        <DepartmentSelector
                          department={this.props.department}
                          selectDepartmentValue={this.props.selectDepartmentValue}
                          classes={classes}
                        />
                        {this.props.department !== this.props.subDepartment && <SubDepartmentSelector
                          department={this.props.department}
                          subDepartment={this.props.subDepartment}
                          selectSubDepartmentValue={
                            this.props.selectSubDepartmentValue
                          }
                          classes={classes}
                        />}
                        <LevelSelector
                          department={this.props.department}
                          subDepartment={this.props.subDepartment}
                          level={this.props.level}
                          selectLevelValue={this.props.selectLevelValue}
                          classes={classes}
                        />
                        {
                          this.props.structure.length > 0?
                        <ReportsSelector
                          current={this.props.currentUser}
                          position={this.props.positionCode}
                          structure={this.props.structure}
                          department={this.props.department}
                          fetchSkills={this.props.fetchSkills}
                          fetchOutcomes={this.props.fetchOutcomes}
                          classes={classes}
                        />:""
                        }
                      </Toolbar>
                    </AppBar>
                    <AppBar position="static">
                      <Tabs
                        value={value}
                        onChange={(event, value) => {
                          this.setState({ value });
                        }}
                      >
                        <Tab label="Competencies" />
                        <Tab label="Outcomes" />
                      </Tabs>
                    </AppBar>
                  </div>
                  {value === 0 && <Main />}
                  {value === 1 && <Outcomes />}
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(
  connect(
    (state, ownProps) => ({
      isLoading: state.isLoading,
      department: state.department,
      subDepartment: state.subDepartment,
      level: state.level,
      structure:state.structure,
      positionCode: state.positionCode,
      currentUser:state.currentUser,
      value: 1
    }),
    {
      selectDepartmentValue,
      selectSubDepartmentValue,
      selectLevelValue,
      fetchSkills,
      fetchOutcomes,
      fetchStructure
    }
  )(App)
);
