import {
  handleActions,
  combineActions
} from 'redux-actions'
import { SHOW_LOADER,HIDE_LOADER } from './../actions/index';
var hash = window.location.hash;
var initState = {
  skills: [],
  department:"MK",
  subDepartment:"MK",
  level:"AMM",
  positionCode:"MK_AMM",
  isLoading:true,
  outcomeTemplate:"",
  outcomeValues:[],
  structure:[{}],
  currentUser:"",
  canEditSkills:true
}

switch (hash){
  case "#PE":
    initState.department = "PE"
    initState.subDepartment = "IC"
    initState.level = "JSE"
    initState.positionCode = "IC_JSE"
  break;
  case "#S":
    initState.department = "S"
    initState.subDepartment = "AS"
    initState.level = "SC"
    initState.positionCode = "AS_SC"
  break;
  case "#BO":
    initState.department = "BO"
    initState.subDepartment = "F"
    initState.level = "JAKR"
    initState.positionCode = "F_JAKR"
  break;
  case "#IT":
    initState.department = "IT"
    initState.subDepartment = "IT"
    initState.level = "SSE"
    initState.positionCode = "IT_SSE"
  break;
  case "#P":
    initState.department = "P"
    initState.subDepartment = "AO"
    initState.level = "DRAO"
    initState.positionCode = "AO_DRAO"
  break;
  case "#BE":
    initState.positionCode = "T_JE"
    initState.department = "BE"
    initState.subDepartment = "T"
    initState.level = "JE"
  break;
  case "#ST":
    initState.department = "ST"
    initState.subDepartment = "HD"
    initState.level = "S"
    initState.positionCode = "HD_S"
  break;
  case "#CI":
    initState.department = "CI"
    initState.subDepartment = "CS"
    initState.level = "CS"
    initState.positionCode = "CS_CS"
  break;  
  case "#MG":
    initState.department = "MG"
    initState.subDepartment = "MG"
    initState.level = "CEO"
    initState.positionCode = "MG_CEO"
  break; 
  default:
  break;
}
export default handleActions({
  LOAD_SKILLS: (state, action) => {
    return { ...state,
      skills: action.payload
    }
  },
  SELECT_DEPARTMENT:(state,action)=>{    
    return {...state, department:action.payload.department, subDepartment:action.payload.sub, level:action.payload.level, positionCode:action.payload.sub+"_"+action.payload.level}
  },
  SELECT_SUB_DEPARTMENT:(state,action)=>{
    return {...state, subDepartment:action.payload, positionCode:action.payload+"_"+state.level}
  },
  SELECT_LEVEL:(state,action)=>{
    return {...state, level:action.payload, positionCode:state.subDepartment+"_"+action.payload}
  },
  LOAD_OUTCOME_TEMPLATE:(state,action)=>{
    return { ...state, outcomeTemplate: action.payload }
  },
  UPDATE_SKILL: (state, action) => {
    if(!state.canEditSkills) return {...state}
    return { ...state,
      skills: state.skills.map(s => s.RowKey === action.payload.RowKey ? {...s, level:action.payload.level}: s)
    }
  },
  UPDATE_OUTCOME: (state, action) => {
    if(!state.canEditSkills) return {...state}
    return { ...state,
      outcomeValues: 
        state.outcomeValues.length === 0?[action.payload]:
        state.outcomeValues.map(s => s.rowKey === action.payload.rowKey ? {...s, outcome:action.payload.outcome}: s)
    }
  },
  LOAD_OUTCOME: (state, action) => {
    return {...state, outcomeValues:action.payload }
  },
  LOAD_STRUCTURE:(state,action) => {
    return {...state, structure:action.payload}
  },
  SELECT_CURRENT_USER:(state,action) =>{
    return {...state, currentUser:action.payload}
  },
  CAN_EDIT_SKILLS:(state, action) => {
    return {...state, canEditSkills:action.payload}
  },
  [combineActions(SHOW_LOADER, HIDE_LOADER)]: (state, action) => { return { ...state, isLoading: action.payload } }
}, initState);