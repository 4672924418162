import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
  tenant: 'arkadium.com',
  clientId: '49f2b32f-9d79-4cb3-bbc8-eecdc234eed3',
  endpoints: {
    api: 'https://arkadium.com/e8df7821-a23d-45ab-8938-f9ff26cb330b',
  },
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export const getToken = () => {
 return authContext.getCachedToken(authContext.config.clientId);
};