import React, { Component } from "react";
import { connect } from "react-redux";
import "./Main.css";
import SkillsList from "../SkillsList";
import { withStyles } from "@material-ui/core/styles";
class Main extends Component {
  render() {
    return (<SkillsList />);
  }
}

export default withStyles()(
  connect(
    (state, ownProps) => ({ isLoading: state.isLoading }),
    {  }
  )(Main)
);