import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {mapping}from "../../departmentMapping";
export const SubDepartmentSelector = ({
  department,
  subDepartment,
  selectSubDepartmentValue,
  classes
}) => (
  <FormControl className={classes.formControl}>
    <InputLabel htmlFor="sub-department-input">Sub</InputLabel>
    <Select
      value={subDepartment}
      inputProps={{
        name: "sub-department",
        id: "sub-department-input"
      }}
      onChange={event => {
        selectSubDepartmentValue(event.target.value, Object.keys(Object.assign(mapping[department].sub[event.target.value].positions, mapping[department].positions))[0]);
      }}
    >
      {Object.keys(mapping[department].sub).map((key)=><MenuItem key={key} value={key}>{mapping[department].sub[key].name}</MenuItem>)}
    </Select>
  </FormControl>
);
