import {
  getToken,
  authContext
} from '../adalConfig';

const baseUrl = process.env.REACT_APP_SKILL_API_APP_BASE_URL
const getHeader = () => {
  return {
    headers: {
      'Authorization': 'Bearer ' + getToken(),
      'Content-Type': "application/json"
    }
  }
}
export const getSkills = (department) => {
  return fetch(`${baseUrl}/skills/list?department=${department}`, getHeader()).then(res => res.json());
}
export const getSkillsByUser = (department,name) => {
  return fetch(`${baseUrl}/skills/skillByUser?department=${department}&name=${name.toLowerCase()}`, getHeader()).then(res => res.json());
}

export const setSkillForUser = (department,skill) => {
  let options = {
    method: 'POST',
    body: JSON.stringify({
      level: skill.level,
      partitionKey: authContext.getCachedUser().userName.toLowerCase(),
      rowKey: skill.RowKey
    }),
    headers: getHeader().headers
  }
  
  return fetch(`${baseUrl}/skills/updateSkill?department=${department}`, options).then(res => res.json());
}

export const setOutcomeForUser = (department,outcomes) => {
  let options = {
    method: 'POST',
    body: JSON.stringify({
      outcome: JSON.stringify(outcomes.outcome),
      partitionKey: authContext.getCachedUser().userName.toLowerCase(),
      rowKey: outcomes.rowKey 
    }),
    headers: getHeader().headers
  }
  return fetch(`${baseUrl}/skills/updateOutcome?department=${department}`, options).then(res => res.json());
}
export const getOutcomesByUser = (department,name) => {
  return fetch(`${baseUrl}/skills/outcomeByUser?department=${department}&name=${name.toLowerCase()}`, getHeader()).then(res => res.json());
}