import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
export const ReportsSelector = ({
  current,
  structure,
  fetchSkills,
  fetchOutcomes,
  department,
  position,
  classes
}) => (
  <FormControl className={classes.formControl}>
    <InputLabel htmlFor="structure-input"></InputLabel>
    <Select
      value={current}
      inputProps={{
        name: "structure",
        id: "structure-input"
      }}
      onChange={event => {
        fetchSkills(department,event.target.value);
        fetchOutcomes(department,position,event.target.value)
      }}
    >
      {structure.map((user,index)=><MenuItem key={index} value={user.email}>{user.username}</MenuItem>)}
    </Select>
  </FormControl>
);