import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {mapping}from "../../departmentMapping";
export const DepartmentSelector = ({ department, selectDepartmentValue, classes}) => (
  <FormControl className={classes.formControl} >
    <InputLabel htmlFor="department-input">Department</InputLabel>
    <Select
      value={department}
      inputProps={{
        name: "department",
        id: "department-input"
      }}
      onChange={event => {
        selectDepartmentValue(event.target.value);
      }}
    >
      {Object.keys(mapping).map((key)=><MenuItem key={key} value={key}>{mapping[key].name}</MenuItem>)}
    </Select>
  </FormControl>
)
