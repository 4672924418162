import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchSkills, saveSkill, updateSkillValue, fetchStructure } from "../actions";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles} from "@material-ui/core/styles";
import {withTheme} from "@material-ui/styles"
import {  Paper } from "@material-ui/core";
import { groupBy, map, values } from "rambda";
import {MKSkillSet} from "./SkillLists/MKSkillSetTable";

const styles = {
  root: {
    display: "block",
    position: "relative"
  },
  table: {
    overflow: "auto auto",
    display: "block",
    height:'90%',
    marginTop:56
  },
  slider: {
    padding: "22px 0px",
    color: "gray"
  },
  track: {
    backgroundColor: "lime"
  },
  thumb: {
    backgroundColor: "lime"
  },
  tableHead: {
    position: "absolute",
    top: 0,
    lineHeight:'56px',
    minWidth:100,
    zIndex:300
  },
  headerBackground: {
    backgroundColor: "rgb(40, 40, 76)",
    display:'block',
    height: 56,
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    zIndex:300
  },
  categoryTitle: {
    color: "rgb(188, 188, 188)",
    fontWeight: "bold",
    marginLeft:10
  },
  categoryTitleHeader:{
    position: "sticky",
    top:120,
    zIndex:1100
  },
  skillText: {}
};
class SkillsList extends Component {
  componentDidMount() {
    this.props.fetchSkills(this.props.department);
  }
  componentWillReceiveProps(props){
    if(this.props.department !== props.department) 
      this.props.fetchSkills(props.department);
  }

  getStyle = (value, num, position) => {
    var style = value >= num
      ? {
          backgroundColor: "rgb(49, 46, 76)",
          color: "rgb(212, 212, 212)"
        }
      : { color: "rgb(212, 212, 212)" };
    // if(position === num) style.borderTop = "5px solid ";
    style.padding="2px"
    style.minWidth="200px"
    style.verticalAlign="top"
    return style
  };

  render() {
    const { classes } = this.props;
    const categories = groupBy(s => s.Category, this.props.skills);
    return (
      <div>
        {values(
          map(
            skills => (
              skills.map(skill =>skill[this.props.positionCode] > 0).find(a=>a === true) &&
              <ExpansionPanel key={skills[0].RowKey} defaultExpanded={this.props.department === "PE"?false:true}>
                <ExpansionPanelSummary style={styles.categoryTitleHeader} expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    style={styles.categoryTitle}
                  >
                    {skills[0].Category}
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <Paper className={classes.root}>
                    {this.props.department === "PE" && 
                      <MKSkillSet
                        skills={skills}
                        props={this.props}
                        classes={classes}
                        getStyle={this.getStyle}
                        levels={[0, 1, 2, 3]}
                      />
                    }
                    {this.props.department !== "PE" && 
                      <MKSkillSet
                        skills={skills}
                        props={this.props}
                        classes={classes}
                        getStyle={this.getStyle}
                        levels={[0, 1, 2, 3, 4]}
                      />
                    }
                  </Paper>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ),
            categories
          )
        )}
      </div>
    );
  }
}

export default (withStyles(styles)(
  connect(
    (state, ownProps) => ({ skills: state.skills, positionCode:state.positionCode, department:state.department }),
    { fetchSkills, saveSkill, updateSkillValue, fetchStructure }
  )(withTheme(SkillsList))
));
