import { fetchOutcomes, saveOutcome } from "../../actions";
import React, { Component } from "react";
import { MDXProvider } from '@mdx-js/react'
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
// import ReactMarkdown from "react-markdown";
import { Paper } from "@material-ui/core";
import ReactMarkdown from "@mdx-js/runtime";
import {OutcomeCheckbox} from "../OutcomeCheckbox"

const styles = {
  root:{
    color: "whitesmoke",
    textAlign: "justify"
  }
};
// const components = {
//   OutcomeCheckbox: props => <OutcomeCheckbox props={props}/>
// }
class Outcomes extends Component {
  findOutcomes = ()=>{
    var outcomes = this.props.outcomeValues.find(o => o.rowKey === this.props.positionCode)
    if(!outcomes){
      outcomes = {
        rowKey :this.props.positionCode,
        outcome:{}
      }
    }
    return outcomes
  }
  getOutcome = id => {
    var outcomes = this.findOutcomes()
    return outcomes.outcome[id]
  }
  updateOutcome = name => event=>{
    var outcomes = this.findOutcomes()
    outcomes.outcome[name] = event.target.checked 
    this.props.saveOutcome(this.props.department,outcomes)
    this.props.fetchOutcomes(this.props.department, this.props.positionCode);
  }
  scope = ()=>{ 
    return {OutcomeCheckbox: props => <OutcomeCheckbox getOutcome={this.getOutcome.bind(this)} domProps={props} props={this.props} onChange={this.updateOutcome.bind(this)}/>}
  }

  constructor(props) {
    super(props)
    this.props.fetchOutcomes(this.props.department, this.props.positionCode, this.currentUser);
  }

  componentWillReceiveProps(props){
    if(this.props.positionCode !== props.positionCode) 
      {
        this.props.fetchOutcomes(props.department, props.positionCode, this.currentUser);
      }
  }
  render() {
    return (
      <Paper style={styles.root}>
        <MDXProvider components={this.scope.bind(this)()}>
          <ReactMarkdown>{this.props.outcomeTemplate}</ReactMarkdown>
        </MDXProvider>
      </Paper>
    );
  }
}

export default withStyles(styles)(
  connect(
    (state, ownProps) => ({
      outcomeTemplate: state.outcomeTemplate,
      outcomeValues:state.outcomeValues,
      positionCode: state.positionCode,
      department:state.department,
      currentUser:state.currentUser
    }),
    { fetchOutcomes, saveOutcome }
  )(Outcomes)
);
